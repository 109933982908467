import { useState } from 'react'
import styles from './GiftSettings.module.scss'
import { bindActionCreators } from 'redux'
import { useDispatch, useSelector } from 'react-redux'
import {sendBalanceAllUser} from '../../../redux/actions/giftActions'

const GiftSettings = () => {
    const [giftData,setGiftData] = useState({
        notificationTitle:"",
        notificationDescription:"",
        balance:0,
    })
    const dispatch = useDispatch()
    const actions = {
        sendBalanceAllUser:bindActionCreators(sendBalanceAllUser,dispatch)
    }
    const giftState = useSelector(state=>state.giftReducer)
    console.log(giftState)
  return (
    <div className={styles["gift-panel"]}>
        <h1 className={styles["gift-panel__title"]}>
            <i className='fas fa-gift'></i> Kullanıcılara Hediye Bakiye Gönder <i className='fas fa-gift'></i>
        </h1>
        {giftState.data && <p className={styles["gift-panel__ok"]}>Tüm Kullanıcılara Bakiye Gönderildi</p>}
        {giftState.errors && <p className={styles["gift-panel__error"]}>{giftState.errors}</p>}
        <div className={styles["gift-panel__send-gift"]}>
            <input 
            value={giftData.notificationTitle}
            onChange={(e)=>setGiftData({...giftData,notificationTitle:e.target.value})}
            className={styles["gift-panel__send-gift__input"]} 
            placeholder='Bildirim Başlığı'/>

            <input 
            value={giftData.notificationDescription}
            onChange={(e)=>setGiftData({...giftData,notificationDescription:e.target.value})}
            className={styles["gift-panel__send-gift__input"]} 
            placeholder='Bildirim Açıklaması'/>

            <input 
            value={giftData.balance}
            type='number'
            onChange={(e)=>setGiftData({...giftData,balance:e.target.value})}
            className={styles["gift-panel__send-gift__input"]} 
            placeholder='Bakiye Miktarı'/> 
            <button onClick={()=>actions.sendBalanceAllUser(giftData)}><i className='fas fa-gift'></i></button>           
        </div>
    </div>
  )
}

export default GiftSettings
