import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import styles from "./Navbar.module.scss";

import { useEffect } from "react";
import { getUserInfo, logout } from "../../redux/actions/userActions";
import { siteSettingsGetAll } from "../../redux/actions/siteSettingsAction";
import images from "../../images";
import Notifications from "../notifications/Notifications";

const Navbar = () => {
  const user = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const actions = {
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
    logout: bindActionCreators(logout, dispatch),
    getSiteSettings: bindActionCreators(siteSettingsGetAll, dispatch),
  };

  useEffect(() => {
    actions.getUserInfo();
    actions.getSiteSettings();
  }, []);

  const [isActiveNavbar, setIsActiveNavbar] = useState(false);

  const siteSettings = useSelector((state) => state.siteSettingsReducer);

  const [isActiveSidebar,setIsActiveSidebar] = useState(false)
  const notReadMessageLength = useSelector((state) => state.notReadMessageLength);


  return (
    <nav className={styles["navbar"]}>
      <div className="container">
      {/* {localStorage.getItem("notificationToken")+ "PLUS"} */}
        <div className={styles["navbar__nav"]}>
          <div className={styles["navbar__nav__links"]}>
            {
              user.id && (
                <>
                  <div className={styles["navbar__nav__links__profile-settings"]}>
                    {isActiveSidebar && <div onClick={()=>setIsActiveSidebar(false)} className={styles["navbar__nav__links__profile-settings__sidebar-closer"]}></div>}
                    <div id={styles[isActiveSidebar && "active"]} className={styles["navbar__nav__links__profile-settings__sidebar"]}>
                      <div className={styles["navbar__nav__links__profile-settings__sidebar__profile-info"]}>
                        <img src={user.imageUrl}  className={styles["navbar__nav__links__profile-settings__sidebar__profile-info__pp-image"]}/>
                        <div className={styles["navbar__nav__links__profile-settings__sidebar__profile-info__balance"]}>
                          <p className={styles["navbar__nav__links__profile-settings__sidebar__profile-info__balance__text"]}>
                            Hesap Bakiyeniz: {user.balance} <i className="fa-solid fa-turkish-lira-sign"></i> 
                          </p>
                          <Link className={styles["navbar__nav__links__profile-settings__sidebar__profile-info__balance__link"]} to={"/balance"}>Bakiye Yükle</Link>
                        </div>
                        <p className={styles["navbar__nav__links__profile-settings__sidebar__profile-info__item"]}> <i className="fas fa-user"></i> {user.username}</p>
                        <p className={styles["navbar__nav__links__profile-settings__sidebar__profile-info__item"]}> <i className="fas fa-envelope"></i> {user.email}</p>
                        <p className={styles["navbar__nav__links__profile-settings__sidebar__profile-info__item"]}> <i className="fas fa-phone"></i> {user.phone}</p>
                      </div>
                      <div className={styles["navbar__nav__links__profile-settings__sidebar__links"]}>
                          <p className={styles["navbar__nav__links__profile-settings__sidebar__links__title"]}>Linkler</p>
                          <Link className={styles["navbar__nav__links__profile-settings__sidebar__links__link"]} to={"/myProfile"}>Profile Git</Link>
                          <Link className={styles["navbar__nav__links__profile-settings__sidebar__links__link"]} to={"/ads"}>Firma'nın Reklamını Yayınla</Link>
                          <Link className={styles["navbar__nav__links__profile-settings__sidebar__links__link"]} to={"/company/add"}>Yeni Firma Ekle</Link>
                          <Link className={styles["navbar__nav__links__profile-settings__sidebar__links__link"]} to={"/advert/add"}>Yeni İlan Ekle</Link>
                          
                          <Link to={"/advert/add"} className={styles["navbar__nav__links__add-advert-btn"]}>Ücretsiz İlan Ver</Link>
                          <Link to={"/company/add"} className={styles["navbar__nav__links__add-company-btn"]}>Firmanı Ekle</Link>
                          <Link to={"/ads"} className={styles["navbar__nav__links__add-ads-btn"]}>Firma Reklamı Oluştur</Link>
                      </div>
                      <div className={styles["navbar__nav__links__profile-settings__sidebar__footer"]}>
                        <button onClick={()=>actions.logout()} className={styles["navbar__nav__links__profile-settings__sidebar__footer__logout"]}>Çıkış Yap</button>
                      </div>
                    </div>
                  </div>
                  <div className={styles["navbar__nav__links__fixed-items"]}>
                    <p onClick={()=>setIsActiveSidebar(true)} id={styles[isActiveSidebar && "active"]} className={styles["navbar__nav__links__profile-settings__bars"]}><i className="fas fa-bars"></i></p>
                    <Link className={styles["navbar__nav__links__messages"]} to={"/chats"}>
                      <div className={styles["navbar__nav__links__messages__count"]}>{
                        notReadMessageLength  
                      }</div>
                      <i class="fa-regular fa-comments"></i>
                    </Link>
                  </div>
                  <Notifications/>
                </>
                
              )
            }
            <Link to={"/"} className={styles["navbar__nav__links__link"]}>Anasayfa</Link>
            <Link to={"/companies"} className={styles["navbar__nav__links__link"]}>Nakliyat Firmaları</Link>
            <Link to={"/adverts"} className={styles["navbar__nav__links__link"]}>Yük İlanları</Link>
            {!user.id && (
              <div className={styles["navbar__nav__links__auth"]}>
                <Link to={"/auth/register"} className={styles["navbar__nav__links__auth__register-btn"]}> <img className={styles["navbar__nav__links__btn-icon"]} src={images.register} /> Kayıt Ol</Link>
                <Link to={"/auth/login"} className={styles["navbar__nav__links__auth__login-btn"]}> <img className={styles["navbar__nav__links__btn-icon"]} src={images.login} /> Giriş Yap</Link>
              </div>
            )}
            <div className={styles["show-links"]}>
              <Link to={"/advert/add"} className={styles["navbar__nav__links__add-advert-btn"]}>Ücretsiz İlan Ver</Link>
              <Link to={"/company/add"} className={styles["navbar__nav__links__add-company-btn"]}>Firmanı Ekle</Link>
              <Link to={"/ads"} className={styles["navbar__nav__links__add-ads-btn"]}>Firma Reklamı Oluştur</Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
