import { useState } from 'react';
import styles from './ForgotPasswordAccept.module.scss';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ForgotPasswordSendEmailAccept } from '../../../../redux/actions/ForgotPasswordAction';

const ForgotPasswordAccept = () => {
  const [password, setPassword] = useState('');
  const [rePassword, setRePassword] = useState('');
  const { code } = useParams();

  const dispatch = useDispatch();
  const actions = {
    ForgotPasswordSendEmailAccept: bindActionCreators(ForgotPasswordSendEmailAccept, dispatch),
  };
  const error = useSelector((x) => x.errorReducer).forgotPasswordAccept;
  const isSuccess = useSelector((x) => x.forgotPasswordReducer)?.emailAccept;

  const submitHandle = (e) => {
    e.preventDefault();
    actions.ForgotPasswordSendEmailAccept({
      code,
      password,
      rePassword,
    });
  };
  return (
    <div className={styles['forgot-password-accept']}>
      <h1 className={styles['forgot-password-accept__title']}>Şifreni Yenile</h1>
      <h6 className={styles['forgot-password-accept__error']}>{error}</h6>
      <h6 className={styles['forgot-password-accept__isSuccess']}>
        {isSuccess && (
          <>
            Şifrenizi yenilediniz. <Link to={'/auth/login'}>Giriş Yapın</Link>{' '}
          </>
        )}
      </h6>
      <form onSubmit={submitHandle} className={styles['forgot-password-accept__form']}>
        <div className={styles['forgot-password-accept__form__group']}>
          <label className={styles['forgot-password-accept__form__group__label']}>
            Yeni Şifren
          </label>
          <input
            onChange={({ target: { value } }) => setPassword(value)}
            value={password}
            className={styles['forgot-password-accept__form__group__input']}
            type='password'
            placeholder='Şifre'
          />
        </div>
        <div className={styles['forgot-password-accept__form__group']}>
          <label className={styles['forgot-password-accept__form__group__label']}>
            Yeni Şifre Tekrarı
          </label>
          <input
            onChange={({ target: { value } }) => setRePassword(value)}
            value={rePassword}
            type='password'
            className={styles['forgot-password-accept__form__group__input']}
            placeholder='Şifre Tekrarı'
          />
        </div>
        {!isSuccess && (
          <div className={styles['forgot-password-accept__form__group']}>
            <input
              className={styles['forgot-password-accept__form__group__button']}
              type='submit'
              value='Parolayı Sıfırla'
            />
          </div>
        )}
        {!isSuccess && (
          <Link
            to={'/auth/forgot-password'}
            className={styles['forgot-password-accept__form__goBack']}>
            Yeni Parola Sıfırlama
          </Link>
        )}

        {isSuccess && (
          <Link to={'/auth'} className={styles['forgot-password-accept__form__goBack']}>
            Giriş Sayfasına Dön
          </Link>
        )}
      </form>
    </div>
  );
};

export default ForgotPasswordAccept;
