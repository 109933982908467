import * as types from '../actionTypes/analyticsTypes';

import { myAxios } from '../myAxios';
import { errorAction } from './errorActions';
import { loadingStateChanged } from './loadingStateActions';

export const analyticsGetAllSuccess = (data) => ({
  type: types.ANALYTICS_GET_ALL,
  payload: data,
});

export const analyticsGetAll = () => {
  return async (dispatch) => {
    //loading start
    dispatch(loadingStateChanged({ analytics: true }));

    const response = await myAxios().get('api/analytics/all-analytics');
    const data = await response.data;

    //loading end
    dispatch(loadingStateChanged({ analytics: false }));

    return dispatch(analyticsGetAllSuccess(data.data));
  };
};