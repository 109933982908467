import React, { useEffect, useState } from 'react';

import styles from './AdvertList.module.css';
import img from '../../images/no.jpg';

import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  advertGetAll,
  advertGetAuthorById,
  advertGetById,
} from '../../redux/actions/advertActions';
import Pagenations from '../pagenations';
import { categoryGetAll } from '../../redux/actions/categoryActions';
import Loading from '../loading/Loading';
import images from '../../images';
import SearchBox from '../search-box/SearchBox';

const AdvertList = () => {
  const advertList = useSelector((state) => state.advertReducer);

  const [adverts, setAdverts] = useState(advertList);
  const categories = useSelector((state) => state.categoriesReducer);

  const [endIndex, setEndIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const isLoadingState = useSelector((state) => state.loadingStateReducer);
  const filterState = useSelector((state) => state.filterReducer);
  const dispatch = useDispatch();
  const actions = {
    getById: bindActionCreators(advertGetById, dispatch),
    getAll: bindActionCreators(advertGetAll, dispatch),
    getAuthorById: bindActionCreators(advertGetAuthorById, dispatch),
    getAllCategories: bindActionCreators(categoryGetAll, dispatch),
  };

  useEffect(() => {
    actions.getAll();
    actions.getAllCategories();
  }, []);

  useEffect(() => {
    setAdverts(advertList);
  }, [advertList]);


  const advertFilterHandle = (advertFilter,advertList)=> {
    
    return advertList.filter(x=>{
      if(
        advertFilter && x &&
        (advertFilter?.fromCountryId == x?.fromCountryId|| !advertFilter.fromCountryId) &&
        (advertFilter?.fromCityId == x?.fromCityId || !advertFilter.fromCityId) &&
        (advertFilter?.toCityId == x?.toCityId|| !advertFilter.toCityId) &&
        (advertFilter?.toCountryId == x?.toCountryId || !advertFilter.toCountryId) &&
        (advertFilter?.categoryId == x?.categoryId || !advertFilter.categoryId) 
      
      ){
        return x
      }else if(!advertFilter) {
        return x;
      }
    })
  }

  return (
    <div>
      {isLoadingState.advertList ? (
        <Loading />
      ) : (
        <div>
          <div className={styles.header}>
            <SearchBox/>            
          </div>
          {adverts.length > 0 ? (
            <ul className={styles['advert-list']}>
              {advertFilterHandle(filterState.advertFilter,adverts?.slice(startIndex, endIndex)).map((advert, i) => (
                <Link to={'/details/advert/' + advert.id} key={i} className={styles['advert-link']}>
                  <li className={styles['advert-list-item']}>
                    <img
                      className={styles['advert-img']}
                      src={advert.imageUrl || img}
                      width={150}
                      alt={advert.title}
                      onError={(e)=>e.target.src = images.noImage}
                    />
                    <div className={styles.advert}>
                      <h3 className={styles['advert-title']}>{advert.title}</h3>
                      <div className={styles['advert-features']}>
                        <span className={styles['load-type']}>Yük Tipi: {advert.productType}</span>
                        <span className={styles['advert-distance']}>
                          <i className='fas fa-location-dot'></i> Nereden: &nbsp;
                          {`${advert.fromWhere}  ${advert.fromWhichCity ? "/ "+advert.fromWhichCity:""}  ${advert.fromWhereTown ? "/ "+advert.fromWhereTown:""} `}
                        </span>

                        <span className={styles['advert-distance']}>
                          <i className='fas fa-location-dot'></i> Nereye: &nbsp;
                          {`${advert.toWhere} ${advert.toWhichWhere ? "/ "+advert.toWhichWhere:""}  ${advert.toWhereTown ? "/ "+advert.toWhereTown:""}`}
                        </span>
                        <span className={styles['advert-distance']}>
                          <i className='fas fa-phone'></i> Telefon: {advert.phone}
                          
                        </span>

                        

                        {advert.offerPrice && (
                          <span className={styles['advert-offerPrice']}>
                            Fiyat Teklifi: &nbsp;
                            {advert.offerPrice} <i className='fa-solid fa-turkish-lira-sign'></i>
                          </span>
                        )}
                      </div>
                    </div>
                  </li>
                </Link>
              ))}
            </ul>
          ) : (
            <h1 className={styles.empty}>Arama sonucunda ilan bulunamadı</h1>
          )}

          <Pagenations
            pageArray={adverts}
            showElementLength={25}
            setEnd={setEndIndex}
            setStart={setStartIndex}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default AdvertList;
