import * as types from '../actionTypes/analyticsTypes'
import * as initialState from '../initialStates'

export default (state = initialState.userList, { type, payload }) => {
    switch (type) {
        case types.ANALYTICS_GET_ALL:
            return payload
        default:
            return state
    }
}
