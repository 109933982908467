import * as types from '../actionTypes/giftTypes'
import * as initialStates from '../initialStates'

export default (state = initialStates.paymentData, { type, payload }) => {
    switch (type) {
        case types.SEND_BALANCE_ALL_USER:
            return state = { ...payload }

        default:
            return state
    }
}
