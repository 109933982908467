import React, { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../components/header';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  companyGetAll,
  companyGetMy,
  companyGetPendingUpdate,
} from '../redux/actions/companyActions';
import { getUserInfo } from '../redux/actions/userActions';
import Navbar from '../components/navbar/Navbar';
import MobileNavbar from '../components/mobileNavbar';
import { messageAdd } from '../redux/actions/chatActions';
import Footer from '../components/footer'
import UseSignalr from '../hooks/useSignalr';
const Layout = () => {
  const isCompanyCrud = useSelector((state) => state.companyCrudReducer);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);
  const connectionRef = useRef(null);
  const actions = {
    getAllCompany: bindActionCreators(companyGetAll, dispatch),
    getAllMyCompany: bindActionCreators(companyGetMy, dispatch),
    getAllPendingUpdateCompany: bindActionCreators(companyGetPendingUpdate, dispatch),
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
    messageAdd: bindActionCreators(messageAdd, dispatch),
  };

  useEffect(() => {
    actions.getUserInfo();
  }, [isCompanyCrud]);
  return (
    <div>
        <Navbar/>
        <Outlet />
        {user.id && <UseSignalr />}
        <Footer/>
        <MobileNavbar/>
    </div>
  );
};

export default Layout;
