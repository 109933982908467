import * as types from '../actionTypes/giftTypes';
import { myAxios } from '../myAxios';

export const sendBalanceAllUserSuccess = (data) => ({
  type: types.SEND_BALANCE_ALL_USER,
  payload: data,
});

export const sendBalanceAllUser = (giftData) => {
  return async (dispatch) => {
    dispatch(sendBalanceAllUserSuccess(""));
    var response = await myAxios().put('api/user/send/gift/balance',giftData);
    var data = await response.data;
    dispatch(sendBalanceAllUserSuccess(data));
  };
};