import React, { useState } from 'react';

import styles from './AdminNavbar.module.scss';
import img from '../../../images/no.jpg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Logout from '../../../components/logout/Logout';

const AdminNavbar = () => {
  const user = useSelector((state) => state.userReducer);
  const [navbarActive,setNavbarActive] = useState(true)
  return (
    <>
      {
        navbarActive && <div className={styles['admin-navbar__closer']} onClick={()=>{setNavbarActive(false)}}></div>
      }
      {
        !navbarActive && <div className={styles['admin-navbar__activator']} onClick={()=>{setNavbarActive(true)}}><i className='fas fa-bars'></i></div>
      }
      <nav id={styles[navbarActive && "active"]} className={styles['admin-navbar']}>
        <h1 className={styles['admin-navbar__title']}>Admin Kontrol Paneli</h1>

        {/* Admin Profil Bölümü */}
        <div className={styles['admin-navbar__profile']}>
          <img className={styles['admin-navbar__profile__image']} src={user.imageUrl || img} alt='' />
          <p className={styles['admin-navbar__profile__full-name']}>
            <i className='fa-solid fa-user'></i> {user.fullname}
          </p>
        </div>

        {/* Admin Linkleri */}
        <div className={styles['admin-navbar__nav']}>
          <Link to='/admin'  className={styles['admin-navbar__nav__link']}>
            <i className='fa-solid fa-home'></i> Anasayfa
          </Link>
          <Link to='/admin/company-settings'  className={styles['admin-navbar__nav__link']}>
            <i className='fa-solid fa-building'></i> Firma Ayarları
          </Link>
          <Link to='/admin/advert-settings'  className={styles['admin-navbar__nav__link']}>
            <i className='fa-solid fa-rectangle-ad'></i> İlan Ayarları
          </Link>
          <Link to='/admin/users'  className={styles['admin-navbar__nav__link']}>
            <i className='fa-solid fa-users'></i> Kullanıcılar
          </Link>
          <Link to='/admin/gift-settings'  className={styles['admin-navbar__nav__link']}>
            <i className='fa-solid fa-gift'></i> Kullanıcılar' Bakiye Gönder
          </Link>
          <Link to={"/admin/site-settings"} className={styles['admin-navbar__nav__link']}>
            <i className='fa-solid fa-gear'></i> Site Ayarları
          </Link>
          <Link  className={styles['admin-navbar__nav__link']}>
            <i className='fa-solid fa-q'></i> <Logout />
          </Link>
        </div>
      </nav>
    </>
  );
};

export default AdminNavbar;
