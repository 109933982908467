import React, { useState } from 'react';

import styles from './Auth.module.scss';
import Login from './modules/login/Login';
import Register from './modules/register/Register';
import { Navigate, Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getUserInfo } from '../../redux/actions/userActions';
import { siteSettingsGetAll } from '../../redux/actions/siteSettingsAction';
import images from '../../images';
import { Helmet } from 'react-helmet';

const Auth = () => {
  const [authBtnActive, setAuthBtnActive] = useState({
    login: true,
    register: false,
  });

  const token = () => localStorage.getItem('token');
  const user = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const actions = {
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
    getSiteSettings: bindActionCreators(siteSettingsGetAll, dispatch),
  };

  const siteSettings = useSelector((state) => state.siteSettingsReducer);

  const [redirectUrl, setRedirectUrl] = useState('');

  useEffect(() => {
    if (token()) {
      actions.getUserInfo();
    }
  }, [token()]);

  useEffect(() => {
    actions.getSiteSettings();
    setRedirectUrl(new URLSearchParams(window.location.search).get('redirectUrl'));
  }, []);

  return (
    <div className={styles['auth']}>
      <Helmet>
        <title>
          {'Hesap Aç - ' +
            (siteSettings.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      <div className={styles['auth__description']}>
        <h1 className={styles['auth__description__title']}>         
          {document.location.host} - Hoş Geldiniz        
        </h1>        
      </div>

      <div className={styles['auth__panel']}>        
        <div className={styles['auth__panel__forms']}>
          <Outlet/>
        </div>
        {user && user.username && <Navigate to={redirectUrl || '/myProfile'} />}
      </div>
    </div>
  );
};

export default Auth;
