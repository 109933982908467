import * as types from '../actionTypes/notificationTypes';
import { myAxios } from '../myAxios';

const getAllNotificationsSuccess = (data) => ({
  type: types.GET_ALL_NOTIFICATIONS_SUCCESS,
  payload: data,
});

export const getAllNotifications = (userId) => {
  return async (dispatch) => {
    const response = await myAxios().get('/api/notification/user/' + userId);
    const data = await response.data;
    dispatch(getAllNotificationsSuccess(data.data));
  };
};

const addNotificationSuccess = () => ({
  type: types.ADD_NOTIFICATION_SUCCESS,
});

export const addNotification = (notificationData, userId) => {
  return async (dispatch) => {
    await myAxios().post('/api/notification/', notificationData);
    const response = await myAxios().get('/api/notification/user/' + userId);
    const data = await response.data;
    dispatch(getAllNotificationsSuccess(data.data));
  };
};

const markAsReadAllAndDeleteSuccess = () => ({
  type: types.MARK_AS_READ_ALL_AND_DELETE_SUCCESS,
});

export const markAsReadAllAndDelete = (userId) => {
  return async (dispatch) => {
    await myAxios().put('/api/Notification/MarkAsReadAllAndDeleted/' + userId);
    const response = await myAxios().get('/api/notification/user/' + userId);
    const data = await response.data;
    dispatch(getAllNotificationsSuccess(data.data));
  };
};

const markAsReadAndDeleteSuccess = () => ({
  type: types.MARK_AS_READ_AND_DELETE_SUCCESS,
});

export const markAsReadAndDelete = (id, userId) => {
  return async (dispatch) => {
    await myAxios().put('/api/notification/markAsReadAndDelete/' + id);
    const response = await myAxios().get('/api/notification/user/' + userId);
    const data = await response.data;
    dispatch(getAllNotificationsSuccess(data.data));
  };
};

const markAsReadSuccess = () => ({
  type: types.MARK_AS_READ_BY_ID_SUCCESS,
});

export const markAsRead = (id, userId) => {
  return async (dispatch) => {
    await myAxios().put('/api/notification/markAsRead/' + id);
    const response = await myAxios().get('/api/notification/user/' + userId);
    const data = await response.data;
    dispatch(getAllNotificationsSuccess(data.data));
  };
};

const markAsReadAllSuccess = () => ({
  type: types.MARK_AS_READ_ALL_BY_USERID_SUCCESS,
});

export const markAsReadAll = (userId) => {
  return async (dispatch) => {
    await myAxios().put('/api/notification/markAsReadAll/' + userId);
    const response = await myAxios().get('/api/notification/user/' + userId);
    const data = await response.data;
    dispatch(getAllNotificationsSuccess(data.data));
  };
};
