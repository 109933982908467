export const normalizeString = (input)=>{
    const replacements = {
        'a': 'a', 'A': 'a',
        'b': 'b', 'B': 'b',
        'c': 'c', 'C': 'c',
        'ç': 'c', 'Ç': 'c',
        'd': 'd', 'D': 'd',
        'e': 'e', 'E': 'e',
        'é': 'e', 'É': 'e',
        'è': 'e', 'È': 'e',
        'ê': 'e', 'Ê': 'e',
        'f': 'f', 'F': 'f',
        'g': 'g', 'G': 'g',
        'ğ': 'g', 'Ğ': 'g',
        'h': 'h', 'H': 'h',
        'ı': 'i', 'I': 'i',
        'İ': 'i', 'i': 'i',
        'j': 'j', 'J': 'j',
        'k': 'k', 'K': 'k',
        'l': 'l', 'L': 'l',
        'm': 'm', 'M': 'm',
        'n': 'n', 'N': 'n',
        'o': 'o', 'O': 'o',
        'ö': 'o', 'Ö': 'o',
        'p': 'p', 'P': 'p',
        'r': 'r', 'R': 'r',
        's': 's', 'S': 's',
        'ş': 's', 'Ş': 's',
        't': 't', 'T': 't',
        'u': 'u', 'U': 'u',
        'ü': 'u', 'Ü': 'u',
        'v': 'v', 'V': 'v',
        'w': 'w', 'W': 'w',
        'x': 'x', 'X': 'x',
        'y': 'y', 'Y': 'y',
        'z': 'z', 'Z': 'z',
        'â': 'a', 'Â': 'a',
        'î': 'i', 'Î': 'i',
        'ô': 'o', 'Ô': 'o',
        'û': 'u', 'Û': 'u',
        'à': 'a', 'À': 'a',
        'ä': 'a', 'Ä': 'a',
        'æ': 'ae', 'Æ': 'ae',
        'ß': 'ss',
        'ñ': 'n', 'Ñ': 'n',
        'ø': 'o', 'Ø': 'o',
        'þ': 'th', 'Þ': 'th',
        'œ': 'oe', 'Œ': 'oe',
        // Burada başka semboller veya harf dönüşümleri eklenebilir
    };

    if(!input){
        return ""
    }
    // Stringin her karakterini kontrol et ve gerektiğinde değiştir
    return input.split('').map(char => replacements[char] || char).join('');
}