import React, { useEffect, useState } from 'react';
import { companyGetAll, companyGetById } from '../../redux/actions/companyActions';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import styles from './CompanyList.module.scss';
import img from '../../images/no.jpg';
import { categoryGetAll } from '../../redux/actions/categoryActions';

import Pagenations from '../pagenations';
import { citiesAndTownsGetAll } from '../../redux/actions/CitiesAndTowns';
import Loading from '../loading/Loading';
import { getCompanyAdsesRandom } from '../../redux/actions/companyAdsActions';
import companyAdsBind from '../../hooks/companyAdsBind';
import {
  getAllCitiesByCountryId,
  getAllCountries,
  getAllTonwsByCityId,
} from '../../redux/actions/geographyActions';
import { addFiltreItem, filterHook, removeFiltreItem } from '../../hooks/filterHooks';
import SearchBox from '../search-box/SearchBox';
import images from '../../images';
import onErrorImageHook from '../../hooks/onErrorImageHook';

const CompanyList = () => {
  const dispatch = useDispatch();
  const companyList = useSelector((state) => state.companyReducer);
  const categories = useSelector((state) => state.categoriesReducer);
  const geographyData = useSelector((state) => state.geographyReducer);
  const isLoadingState = useSelector((state) => state.loadingStateReducer);
  const [companies, setCompanies] = useState(companyList);
  const [endIndex, setEndIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterObj, setFilterObj] = useState({});
  const [isShowFilter, setIsShowFilter] = useState(false);
  const [geography, setGeography] = useState({
    cities: [],
    towns: [],
    countries: [],
  });

  const actions = {
    getById: bindActionCreators(companyGetById, dispatch),
    getAllCompany: bindActionCreators(companyGetAll, dispatch),
    getAllCategory: bindActionCreators(categoryGetAll, dispatch),
    getAllCities: bindActionCreators(citiesAndTownsGetAll, dispatch),
    getAllCountries: bindActionCreators(getAllCountries, dispatch),
    getAllCitiesByCountryId: bindActionCreators(getAllCitiesByCountryId, dispatch),
    getAllTonwsByCityId: bindActionCreators(getAllTonwsByCityId, dispatch),
  };

  useEffect(() => {
    actions.getAllCompany();
    actions.getAllCategory();
    actions.getAllCities();
    actions.getAllCountries();
  }, []);

  useEffect(() => {
    if (companyList) {
      setCompanies(companyList);
    }
  }, [companyList]);

  

  const filterState = useSelector((state)=>state.filterReducer)
    

  const handleFilterRunner = (x) => {
    if(
      (x.countryId == filterState.companyFilter?.countryId || !filterState.companyFilter?.countryId) &&
      (x.cityId == filterState.companyFilter?.cityId || !filterState.companyFilter?.cityId) &&
      (x.townId == filterState.companyFilter?.townId || !filterState.companyFilter?.townId) &&
      (x.categoryId == filterState.companyFilter?.categoryId || !filterState.companyFilter?.categoryId
      )
    ){
      return x
    }else if(!filterState.companyFilter) {
      return x
    }
  }

  useEffect(()=>{
    setCurrentPage(1)
  },[filterState])



  return (
    <div className={styles['page']}>
      {isLoadingState.companyList ? (
        <Loading />
      ) : (
        <>
          {/* filtreleme işlemleri */}
          <div className={styles['page__header']}>
           <SearchBox/>
          </div>
          {companies?.length === 0 && (
            <h1 className={styles['page__empty-text']}>
              Arama sonucunda nakliye firması bulunamadı
            </h1>
          )}
          <ul className={styles['page__company-list']}>
            {/* companies */}
            {
            companies?.filter(handleFilterRunner).slice(startIndex, endIndex).length > 0 ? companies?.filter(handleFilterRunner).slice(startIndex, endIndex).map((company, i) => {
              return (
                company?.id && (
                  <Link key={i} to={'/details/company/' + company.id}>
                    <li data-isAds={company.isAds} className={styles['page__company-list__item']}>
                      <img
                        onError={onErrorImageHook}
                        src={company.image || img}
                        className={styles['page__company-list__item__image']}
                        alt={company?.name}
                      />
                      <div className={styles['page__company-list__item__company']}>
                        <h3 className={styles['page__company-list__item__company__name']}>
                          {company?.name}
                        </h3>
                        <span className={styles['page__company-list__item__company__city']}>
                          <i className='fas fa-location-dot'></i> {company.country + ' /'}{' '}
                          {company.city} {company.town && '/' + company.town}
                        </span>
                        <span className={styles['page__company-list__item__company__phones']}>
                          <span
                            className={styles['page__company-list__item__company__phones__phone']}>
                            <i className='fas fa-phone'></i> {company.phone}
                          </span>
                        </span>
                      </div>
                    </li>
                  </Link>
                )
              );
            }):<p className={styles["page__empty-text"]}><font color="black" >{filterState.companyFilter?.countryText}/{filterState.companyFilter?.cityText}/{filterState.companyFilter?.townText}, {filterState.companyFilter?.categoryText}</font> Arama sonucunda nakliyat firması bulunamadı</p> 
            }
          </ul>

          <Pagenations
            pageArray={companies.filter(handleFilterRunner)}
            setEnd={setEndIndex}
            setStart={setStartIndex}
            showElementLength={50}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}
    </div>
  );
};

export default CompanyList;
