import { useEffect, useRef, useState } from 'react'
import images from '../../images'
import styles from './SearchBox.module.scss'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import FilterItem from './modules/filter-item';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCitiesByCountryId, getAllCountries, getAllTonwsByCityId } from '../../redux/actions/geographyActions';
import { bindActionCreators } from 'redux';
import { Link, Navigate, useHref, useLocation, useNavigate } from 'react-router-dom';
import { categoryGetAll } from '../../redux/actions/categoryActions';
import { addAdvertFilter, addCompanyFilter, resetAdvertFilter, resetCompanyFilter } from '../../redux/actions/filterActions';
import { normalizeString } from '../../hooks/normalizeString';
import { getAllCitiesByFromWhereCountryId, getAllCitiesByToWhereCountryId, getAllFormDataCountries, getAllTonwsByFromWhereCityId, getAllTonwsByToWhereCityId } from '../../redux/actions/formDataGeographyActions';

const SearchBox = () => {
  const dispatch = useDispatch()
  const actions = {
    getAllCountries:bindActionCreators(getAllCountries,dispatch),
    getAllFormDataCountries:bindActionCreators(getAllFormDataCountries,dispatch),
    getAllCitiesByCountryId:bindActionCreators(getAllCitiesByCountryId,dispatch),
    getAllCitiesByFromWhereCountryId:bindActionCreators(getAllCitiesByFromWhereCountryId,dispatch),
    getAllCitiesByToWhereCountryId:bindActionCreators(getAllCitiesByToWhereCountryId,dispatch),
    getAllTonwsByToWhereCityId:bindActionCreators(getAllTonwsByToWhereCityId,dispatch),
    getAllTonwsByFromWhereCityId:bindActionCreators(getAllTonwsByFromWhereCityId,dispatch),
    getAllTonwsByCityId:bindActionCreators(getAllTonwsByCityId,dispatch),
    categoryGetAll:bindActionCreators(categoryGetAll,dispatch),
    addCompanyFilter:bindActionCreators(addCompanyFilter,dispatch),
    addAdvertFilter:bindActionCreators(addAdvertFilter,dispatch),
    resetAdvertFilter:bindActionCreators(resetAdvertFilter,dispatch),
    resetCompanyFilter:bindActionCreators(resetCompanyFilter,dispatch),
  }

  useEffect(()=>{
    actions.getAllCountries()
    actions.getAllFormDataCountries()
    actions.categoryGetAll()
  },[])

  const [text, setText] = useState('');
  const { transcript, resetTranscript } = useSpeechRecognition();
  const [isMicrophone,setIsMicrophone] = useState(false)

  const handleListen = () => {
    if (SpeechRecognition.browserSupportsSpeechRecognition()) {
      SpeechRecognition.startListening({ continuous: true, language:"tr" });
      setIsMicrophone(true)
    } else {
      alert('Tarayıcınız mikrofonu desteklemiyor.');
    }
  };

  const stopListening = () => {
    SpeechRecognition.stopListening();
    setText(transcript.replaceAll(/[^a-zA-Z0-9ğüşıöçĞÜŞİÖÇ\s]/g, ''));
    setIsMicrophone(false)
    resetTranscript();
  };

  useEffect(()=>{
    if(transcript){
      setText(transcript)
    }
  },[transcript])

  const [searchType,setSearchType] = useState("company")

  const [advertSearchData,setAdvertSearchData] = useState({
    fromCountryId:0,
    fromCountryText:"",

    fromCityId:0,
    fromCityText:"",

    fromTownId:0,
    fromTownText:"",
    // asdasd
    toCountryId:0,
    toCountryText:"",

    toCityId:0,
    toCityText:0,

    toTownId:0,
    toTownText:"",

    categoryId:0,
    categoryText:""
  })

  const [companySearchData,setCompanySearchData] = useState({
    countryText:"",
    cityText:"",
    townText:"",
    categoryText:"",
    countryId:0,
    cityId:0,
    townId:0,
    categoryId:0
  })

  const formGeographyState = useSelector((state)=>state.formDataGeography)
  const geographyState = useSelector((state)=>state.geographyReducer)
  const categoriesState = useSelector((state)=>state.categoriesReducer)
  const filterState = useSelector((state)=>state.filterReducer)



  useEffect(()=>{
    if(filterState.companyFilter){
      setCompanySearchData(filterState.companyFilter)
    }

    if(filterState.advertFilter) {
      setAdvertSearchData(filterState.advertFilter)
    }
  },[filterState])

  const [isActiveSelectBoxId,setIsActiveSelectBoxId] = useState(0)

  return (
    <div className={styles["search-box"]}>      
      <header className={styles["search-box__header"]}>

        <p className={styles["search-box__search-label"]}>Ne Arıyorsun ?</p>
        <div className={styles["search-box__header__types"]}>
          <div onClick={()=>setSearchType("company")} data-active={searchType == "company"} className={styles["search-box__header__types__type"]}>
            Firma
          </div>
          <div onClick={()=>setSearchType("advert")} data-active={searchType == "advert"} className={styles["search-box__header__types__type"]}>
            Yük İlanı
          </div>
        </div>
        <div  className={styles["search-box__header__search"]}>
          {searchType == "none" && (
          <>
            <img src={images.searchIcon} className={styles["search-box__header__search__icon"]} />
            <input onClick={()=>isMicrophone && stopListening()} value={text} onChange={(e)=>setText(e.target.value)}  className={styles["search-box__header__search__input"]} placeholder='sizin için en uygun yükü veya nakliye firmasını arayın'/>
            <button onClick={()=>{
              if(isMicrophone) {
                stopListening()
              }else {
                handleListen()
              }
            }} className={styles["search-box__header__search__button"]}>
            <img src={isMicrophone ? images.microphoneIconActive:images.microphoneIcon}/>
            </button>
          </>
        )}
        </div>



      
        <p className={styles["search-box__search-label"]}>Detaylı arama yapmak için filtreleri kullanabilirsiniz</p>
        <div className={styles["search-box__filters"]}>
          {
            searchType == "advert" && (
              <>
                <FilterItem {...{
                  icon:images.transportationRight,
                  title:"Nereden",
                  description:formGeographyState?.countries?.filter(x=>x.id == advertSearchData.fromCountryId)[0]?.name || "Seçiniz",
                  sub:(formGeographyState?.fromCities?.filter(x=>x.id == advertSearchData.fromCityId)[0]?.name || "") + " " + (formGeographyState.fromTowns?.filter(x=>x.id == advertSearchData.fromTownId)[0]?.name || ""),
                  popupChildren:(
                    <div className={styles["filter-popup"]}>                      
                      <div className={styles["filter-popup__group"]}>
                          <label className={styles["filter-popup__group__label"]}>Ülke Adı</label>
                          <input onFocus={()=>setIsActiveSelectBoxId("fromCountries")} value={advertSearchData.fromCountryText} onChange={(e)=>setAdvertSearchData({...advertSearchData,fromCountryText:e.target.value})} className={styles["filter-popup__group__input"]} placeholder='Ülke Seçiniz'/>
                          <div id={isActiveSelectBoxId == "fromCountries" && styles["active"]} className={styles["filter-popup__group__select-box"]}>
                            {
                              formGeographyState?.countries?.filter(x=>normalizeString(x.name?.toUpperCase())?.includes(normalizeString(advertSearchData?.fromCountryText?.toUpperCase()))).slice(0,7).map((x)=>(
                                <div onClick={()=>{
                                  setAdvertSearchData({...advertSearchData,fromCountryText:x.name,fromCityText:"",fromTownText:"",fromCountryId:x.id,fromCityId:0,fromTownId:0})
                                  actions.getAllCitiesByFromWhereCountryId(x.id)
                                  setIsActiveSelectBoxId("")
                                }} className={styles["filter-popup__group__select-box__item"]}>
                                  {x.name}
                                </div>  
                              ))
                            }                                                  
                          </div>
                      </div>
                      <div className={styles["filter-popup__group"]}>
                          <label className={styles["filter-popup__group__label"]}>İl Adı</label>
                          <input                            
                          onFocus={()=>setIsActiveSelectBoxId("fromCities")} 
                          value={advertSearchData.fromCityText} onChange={(e)=>setAdvertSearchData({...advertSearchData,fromCityText:e.target.value})} className={styles["filter-popup__group__input"]} placeholder='Ülke Seçiniz'/>
                          <div id={isActiveSelectBoxId == "fromCities" && styles["active"]} className={styles["filter-popup__group__select-box"]}>
                            {
                              formGeographyState?.fromCities?.filter(x=>normalizeString(x.name?.toUpperCase()).includes(normalizeString(advertSearchData.fromCityText?.toUpperCase()))).slice(0,7).map((x)=>(
                                <div onClick={()=>{
                                  setAdvertSearchData({...advertSearchData,fromCityText:x.name,fromTownText:"",fromCityId:x.id,fromTownId:0})
                                  actions.getAllTonwsByFromWhereCityId(x.id)
                                  setIsActiveSelectBoxId("")
                                }} className={styles["filter-popup__group__select-box__item"]}>
                                  {x.name}
                                </div>  
                              ))
                            }                                                  
                          </div>
                      </div>
                      <div className={styles["filter-popup__group"]}>
                          <label className={styles["filter-popup__group__label"]}>İlçe Adı</label>
                          <input                            
                          onFocus={()=>setIsActiveSelectBoxId("fromTowns")} 
                          value={advertSearchData.fromTownText} onChange={(e)=>setAdvertSearchData({...advertSearchData,fromTownText:e.target.value})} className={styles["filter-popup__group__input"]} placeholder='Ülke Seçiniz'/>
                          <div id={isActiveSelectBoxId == "fromTowns" && styles["active"]} className={styles["filter-popup__group__select-box"]}>
                            {
                              formGeographyState?.fromTowns?.filter(x=>normalizeString(x.name?.toUpperCase()).includes(normalizeString(advertSearchData.fromTownText?.toUpperCase()))).slice(0,7).map((x)=>(
                                <div onClick={()=>{
                                  setAdvertSearchData({...advertSearchData,fromTownText:x.name,fromTownId:x.id})
                                  setIsActiveSelectBoxId("")
                                }} className={styles["filter-popup__group__select-box__item"]}>
                                  {x.name}
                                </div>  
                              ))
                            }                                                  
                          </div>
                      </div>
                      <button onClick={()=>{
                          setAdvertSearchData({...advertSearchData,fromCityText:"",fromCountryText:"",fromTownText:"",fromCityId:0,fromCountryId:0,fromTownId:0})
                        }} 
                        className={styles["filter-popup__reset-btn"]}
                        >Seçimleri Sıfırla</button>
                    </div>
                  )
                }}/>

                <FilterItem {...{
                    icon:images.transportationLeft,
                    title:"Nereye",
                    description:formGeographyState.countries?.filter(x=>x.id == advertSearchData.toCountryId)[0]?.name || "Seçiniz",
                    sub:(formGeographyState.toCities?.filter(x=>x.id == advertSearchData.toCityId)[0]?.name || "") + " " + (formGeographyState.toTowns?.filter(x=>x.id == advertSearchData.toTownId)[0]?.name || ""),
                    popupChildren:(
                      <div className={styles["filter-popup"]}>                      
                        <div className={styles["filter-popup__group"]}>
                            <label className={styles["filter-popup__group__label"]}>Ülke Adı</label>
                            <input onFocus={()=>setIsActiveSelectBoxId("toCountries")} value={advertSearchData.toCountryText} onChange={(e)=>setAdvertSearchData({...advertSearchData,toCountryText:e.target.value})} className={styles["filter-popup__group__input"]} placeholder='Ülke Seçiniz'/>
                            <div id={isActiveSelectBoxId == "toCountries" && styles["active"]} className={styles["filter-popup__group__select-box"]}>
                              {
                                formGeographyState?.countries?.filter(x=>normalizeString(x.name?.toUpperCase()).includes(normalizeString(advertSearchData.toCountryText?.toUpperCase()))).slice(0,7).map((x)=>(
                                  <div onClick={()=>{
                                    setAdvertSearchData({...advertSearchData,toCountryText:x.name,toCityText:"",toTownText:"",toCountryId:x.id,toCityId:0,toTownId:0})
                                    actions.getAllCitiesByToWhereCountryId(x.id)
                                    setIsActiveSelectBoxId("")
                                  }} className={styles["filter-popup__group__select-box__item"]}>
                                    {x.name}
                                  </div>  
                                ))
                              }                                                  
                            </div>
                        </div>
                        <div className={styles["filter-popup__group"]}>
                            <label className={styles["filter-popup__group__label"]}>İl Adı</label>
                            <input                            
                            onFocus={()=>setIsActiveSelectBoxId("toCities")} 
                            value={advertSearchData.toCityText} onChange={(e)=>setAdvertSearchData({...advertSearchData,toCityText:e.target.value})} className={styles["filter-popup__group__input"]} placeholder='Ülke Seçiniz'/>
                            <div id={isActiveSelectBoxId == "toCities" && styles["active"]} className={styles["filter-popup__group__select-box"]}>
                              {
                                formGeographyState?.toCities?.filter(x=>normalizeString(String(x.name).toUpperCase())?.includes(normalizeString(String(advertSearchData?.toCityText).toUpperCase()))).slice(0,7)?.map((x)=>(
                                  <div onClick={()=>{
                                    setAdvertSearchData({...advertSearchData,toCityText:x.name,toTownText:"",toCityId:x.id,toTownId:0})
                                    actions.getAllTonwsByToWhereCityId(x.id)
                                    setIsActiveSelectBoxId("")
                                  }} className={styles["filter-popup__group__select-box__item"]}>
                                    {x.name}
                                  </div>  
                                ))
                              }                                                  
                            </div>
                        </div>
                        <div className={styles["filter-popup__group"]}>
                            <label className={styles["filter-popup__group__label"]}>İlçe Adı</label>
                            <input                            
                            onFocus={()=>setIsActiveSelectBoxId("toTowns")} 
                            value={advertSearchData.toTownText} onChange={(e)=>setAdvertSearchData({...advertSearchData,toTownText:e.target.value})} className={styles["filter-popup__group__input"]} placeholder='Ülke Seçiniz'/>
                            <div id={isActiveSelectBoxId == "toTowns" && styles["active"]} className={styles["filter-popup__group__select-box"]}>
                              {
                                formGeographyState?.toTowns?.filter(x=>normalizeString(x.name?.toUpperCase()).includes(normalizeString(advertSearchData.toTownText?.toUpperCase()))).slice(0,7).map((x)=>(
                                  <div onClick={()=>{
                                    setAdvertSearchData({...advertSearchData,toTownText:x.name,toTownId:x.id})
                                    setIsActiveSelectBoxId("")
                                  }} className={styles["filter-popup__group__select-box__item"]}>
                                    {x.name}
                                  </div>  
                                ))
                              }                                                  
                            </div>
                        </div>
                        <button onClick={()=>{
                            setAdvertSearchData({...advertSearchData,toCityText:"",toCountryText:"",toTownText:"",toCityId:0,toCountryId:0,toTownId:0})
                          }} 
                          className={styles["filter-popup__reset-btn"]}
                          >Seçimleri Sıfırla</button>
                    </div>
                    )
                }}/>       

                  <FilterItem {...{
                    icon:images.transportationLeft,
                    title:"Kategori",
                    description:categoriesState?.filter(x=>x.id == advertSearchData.categoryId)[0]?.name || "Seçiniz...",
                    sub:"Kategori Ara",
                    popupChildren:(
                      <div className={styles["filter-popup"]}>
                        <div className={styles["filter-popup__group"]}>
                            <label className={styles["filter-popup__group__label"]}>Kategori Adı</label>
                            <input onFocus={()=>setIsActiveSelectBoxId("categories")} value={advertSearchData.categoryText} onChange={(e)=>setAdvertSearchData({...advertSearchData,categoryText:e.target.value})} className={styles["filter-popup__group__input"]} placeholder='Kategori Seçiniz'/>
                            <div id={isActiveSelectBoxId == "categories" && styles["active"]} className={styles["filter-popup__group__select-box"]}>
                              {
                                categoriesState?.filter(x=>normalizeString(x.name?.toUpperCase()).includes(normalizeString(advertSearchData.categoryText?.toUpperCase()))).map((x)=>(
                                  <div onClick={()=>{
                                    setAdvertSearchData({...advertSearchData,categoryText:x.name,categoryId:x.id})
                                    setIsActiveSelectBoxId("")
                                  }} className={styles["filter-popup__group__select-box__item"]}>
                                    {x.name}
                                  </div>  
                                ))
                              }                                                  
                            </div>
                        </div>
                        <button onClick={()=>{
                          setAdvertSearchData({...advertSearchData,categoryText:"",categoryId:0})
                        }} 
                        className={styles["filter-popup__reset-btn"]}
                        >Seçimleri Sıfırla</button>
                      </div>
                    )
                }}/>
              </>
            )
          }

          {
            searchType == "company" && (
              <>
                <FilterItem {...{
                  icon:images.transportationRight,
                  title:"Firma Konumu",
                  description:geographyState?.countries?.filter(x=>x.id == companySearchData.countryId)[0]?.name || "Seçiniz..",
                  sub:`${geographyState?.cities?.filter(x=>x.id == companySearchData.cityId)[0]?.name || ""} ${("- "+(geographyState?.towns?.filter(x=>x.id == companySearchData.townId)[0]?.name || ""))}`,
                  popupChildren:(
                    <div className={styles["filter-popup"]}>                      
                      <div className={styles["filter-popup__group"]}>
                          <label className={styles["filter-popup__group__label"]}>Ülke Adı</label>
                          <input onFocus={()=>setIsActiveSelectBoxId("countries")} value={companySearchData.countryText} onChange={(e)=>setCompanySearchData({...companySearchData,countryText:e.target.value})} className={styles["filter-popup__group__input"]} placeholder='Ülke Seçiniz'/>
                          <div id={isActiveSelectBoxId == "countries" && styles["active"]} className={styles["filter-popup__group__select-box"]}>
                            {
                              geographyState.countries?.filter(x=>normalizeString(x.name?.toUpperCase()).includes(normalizeString(companySearchData.countryText?.toUpperCase()))).slice(0,7).map((x)=>(
                                <div onClick={()=>{
                                  setCompanySearchData({...companySearchData,countryText:x.name,cityText:"",townText:"",countryId:x.id,cityId:0,townId:0})
                                  actions.getAllCitiesByCountryId(x.id)
                                  console.log(companySearchData)
                                  setIsActiveSelectBoxId("")
                                }} className={styles["filter-popup__group__select-box__item"]}>
                                  {x.name}
                                </div>  
                              ))
                            }                                                  
                          </div>
                      </div>
                      <div className={styles["filter-popup__group"]}>
                          <label className={styles["filter-popup__group__label"]}>İl Adı</label>
                          <input                            
                          onFocus={()=>setIsActiveSelectBoxId("cities")} 
                          value={companySearchData.cityText} onChange={(e)=>setCompanySearchData({...companySearchData,cityText:e.target.value})} className={styles["filter-popup__group__input"]} placeholder='Ülke Seçiniz'/>
                          <div id={isActiveSelectBoxId == "cities" && styles["active"]} className={styles["filter-popup__group__select-box"]}>
                            {
                              geographyState.cities?.filter(x=>normalizeString(x.name?.toUpperCase()).includes(normalizeString(companySearchData.cityText?.toUpperCase()))).slice(0,7).map((x)=>(
                                <div onClick={()=>{
                                  setCompanySearchData({...companySearchData,cityText:x.name,townText:"",cityId:x.id,townId:0})
                                  actions.getAllTonwsByCityId(x.id)
                                  setIsActiveSelectBoxId("")
                                }} className={styles["filter-popup__group__select-box__item"]}>
                                  {x.name}
                                </div>  
                              ))
                            }                                                  
                          </div>
                      </div>
                      <div className={styles["filter-popup__group"]}>
                          <label className={styles["filter-popup__group__label"]}>İlçe Adı</label>
                          <input                            
                          onFocus={()=>setIsActiveSelectBoxId("towns")} 
                          value={companySearchData.townText} onChange={(e)=>setCompanySearchData({...companySearchData,townText:e.target.value})} className={styles["filter-popup__group__input"]} placeholder='Ülke Seçiniz'/>
                          <div id={isActiveSelectBoxId == "towns" && styles["active"]} className={styles["filter-popup__group__select-box"]}>
                            {
                              geographyState.towns?.filter(x=>normalizeString(x.name?.toUpperCase()).includes(normalizeString(companySearchData.townText?.toUpperCase()))).slice(0,7).map((x)=>(
                                <div onClick={()=>{
                                  setCompanySearchData({...companySearchData,townText:x.name,townId:x.id})
                                  setIsActiveSelectBoxId("")
                                  console.log(companySearchData)
                                }} className={styles["filter-popup__group__select-box__item"]}>
                                  {x.name}
                                </div>  
                              ))
                            }                                                  
                          </div>
                      </div>
                      <button onClick={()=>{
                          setCompanySearchData({...companySearchData,cityText:"",countryText:"",townText:"",cityId:0,countryId:0,townId:0})
                        }} 
                        className={styles["filter-popup__reset-btn"]}
                        >Seçimleri Sıfırla</button>
                    </div>
                  )
                }}/>
               
                <FilterItem {...{
                    icon:images.transportationLeft,
                    title:"Kategori",
                    description:categoriesState?.filter(x=>x.id == companySearchData.categoryId)[0]?.name || "Seçiniz...",
                    sub:"Kategori Ara",
                    popupChildren:(
                      <div className={styles["filter-popup"]}>
                        <div className={styles["filter-popup__group"]}>
                            <label className={styles["filter-popup__group__label"]}>Kategori Adı</label>
                            <input onFocus={()=>setIsActiveSelectBoxId("categories")} value={companySearchData.categoryText} onChange={(e)=>setCompanySearchData({...companySearchData,categoryText:e.target.value})} className={styles["filter-popup__group__input"]} placeholder='Kategori Seçiniz'/>
                            <div id={isActiveSelectBoxId == "categories" && styles["active"]} className={styles["filter-popup__group__select-box"]}>
                              {
                                categoriesState?.filter(x=>normalizeString(x.name?.toUpperCase()).includes(normalizeString(companySearchData.categoryText?.toUpperCase()))).map((x)=>(
                                  <div onClick={()=>{
                                    setCompanySearchData({...companySearchData,categoryText:x.name,categoryId:x.id})
                                    setIsActiveSelectBoxId("")
                                  }} className={styles["filter-popup__group__select-box__item"]}>
                                    {x.name}
                                  </div>  
                                ))
                              }                                                  
                            </div>
                        </div>
                        <button onClick={()=>{
                          setCompanySearchData({...companySearchData,categoryText:"",categoryId:0})
                        }} 
                        className={styles["filter-popup__reset-btn"]}
                        >Seçimleri Sıfırla</button>
                      </div>
                    )
                }}/>
              </>
            )
          }
        </div>
        <Link 
        to={searchType == "company"?"/companies":"/adverts"}
        onClick={()=>{
          isMicrophone && stopListening()    
          if(searchType == "company"){
            actions.addCompanyFilter(companySearchData)
          }else if(searchType == "advert"){
            actions.addAdvertFilter(advertSearchData)
          }   

        }} 
        className={styles["search-box__search-btn"]}
        >Ara</Link>        
      </header>
    </div>
  )
}

export default SearchBox
