import React, { useEffect, useState } from 'react';

import styles from './AdvertList.module.css';

import img from '../../../../images/no.jpg';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { advertGetMy } from '../../../../redux/actions/advertActions';
import Pagenations from '../../../../components/pagenations';

const AdvertList = () => {
  const user = useSelector((state) => state.userReducer);
  const myAdverts = useSelector((state) => state.advertGetByReducer) || [];
  const dispatch = useDispatch();
  const actions = {
    getMyAdverts: bindActionCreators(advertGetMy, dispatch),
  };

  useEffect(() => {
    if (user.id) {
      actions.getMyAdverts(user.id);
    }
  }, [user]);

  const [endIndex, setEndIndex] = useState(0);
  const [startIndex, setStartIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <div className={styles['advert-list']}>
      <div className={styles.header}>
        <h1 className={styles.title}>İlanlarınız</h1>{' '}
        <Link to={'/advert/add'} className={styles.addAdvertBtn}>
          Yeni İlan Ekle
        </Link>
      </div>
      <table>
        <thead>
          <tr className={styles['advert-titles']}>
            <th>Resim</th>
            <th>İlan Başlığı</th>
            <th>Kontroller</th>
          </tr>
        </thead>
        <tbody>
          {myAdverts &&
            myAdverts.map &&
            myAdverts.slice(startIndex, endIndex).map((advert, i) => (
              <tr key={i}>
                <td>
                  <img className={styles.img} src={advert.imageUrl || img} />
                </td>
                <td>
                  <h4 className={styles['advert-name']}>{advert.title}</h4>
                </td>
                <td>
                  <div className={styles['controls']}>
                    <Link to={'/advert/edit/' + advert.id}>
                      <button className={styles['edit-btn']}>Düzenle</button>
                    </Link>
                    <Link to={'/advert/delete/' + advert.id}>
                      <button className={styles['delete-btn']}>Kaldır</button>
                    </Link>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <Pagenations
        setStart={setStartIndex}
        setEnd={setEndIndex}
        pageArray={myAdverts}
        showElementLength={10}
        scrollTo={true}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
      />
    </div>
  );
};

export default AdvertList;
