import * as types from '../actionTypes/filterTypes';

import { errorAction } from './errorActions';
import { loadingStateChanged } from './loadingStateActions';

export const addCompanyFilterSuccess = (data) => ({
    type: types.ADD_COMPANY_FILTER,
    payload: data,
});
  
export const addCompanyFilter = (filterObj) => {
    return async (dispatch) => {    
        return dispatch(addCompanyFilterSuccess(filterObj));
    };
};


export const addAdvertFilterSuccess = (data) => ({
    type: types.ADD_ADVERT_FILTER,
    payload: data,
});
  
export const addAdvertFilter = (filterObj) => {
    return async (dispatch) => {    
        return dispatch(addAdvertFilterSuccess(filterObj));
    };
};

export const resetAdvertFilterSuccess = (data) => ({
    type: types.RESET_ADVERT_FILTER,
    payload: data,
});
  
export const resetAdvertFilter = () => {
    return async (dispatch) => {    
        return dispatch(resetAdvertFilterSuccess());
    };
};

export const resetCompanyFilterSuccess = (data) => ({
    type: types.RESET_COMPANY_FILTER,
    payload: data,
});
  
export const resetCompanyFilter = () => {
    return async (dispatch) => {    
        return dispatch(resetCompanyFilterSuccess());
    };
};