import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from '../layouts/Layout';

import './app.css';

import Home from './Home';
import About from './About';
import Auth from './Auth/Auth';
import Profile from './Profile';
import DeleteAdvert from './AdvertPages/DeleteAdvert/';
import AddAdvert from './AdvertPages/AddAdvert/';
import UpdateAdvert from './AdvertPages/UpdateAdvert/';
import UpdateCompany from './CompanyPages/UpdateCompany/';
import AddCompany from './CompanyPages/AddCompany/';
import DeleteCompany from './CompanyPages/DeleteCompany/';
import AdminLayout from '../layouts/AdminLayout';
import AdminProfile from './AdminPages/AdminProfile/';
import CompanySettings from './AdminPages/CompanySettings/';
import AdvertSettings from './AdminPages/AdvertSettings/';
import UserSettings from './AdminPages/UserSettings/';
import Advert from './AdvertPages/Adverts/';
import Companies from './CompanyPages/Companies/';
import NotFound from './NotFound/NotFound';
import AdvertDetails from './AdvertPages/AdvertDetails/AdvertDetails';
import CompanyDetails from './CompanyPages/CompanyDetails/CompanyDetails';
import IsLogginLayout from '../layouts/IsLogginLayout';
import Kvkk from './KVKK/Kvkk';
import BalanceOrder from './BalanceOrder';
import AdsPage from './AdsPage';
import Chats from './Chats';
import Chat from '../components/chat';
import PrivaciyPolicy from './PrivacyPolicy/PrivaciyPolicy';
import ForgotPassword from './Auth/modules/forgot-password';
import ForgotPasswordAccept from './Auth/modules/forgot-password-accept';
import Login from './Auth/modules/login/Login';
import Register from './Auth/modules/register/Register';
import SiteSettings from '../components/admin-components/site-settings/SiteSettings';
import GiftSettings from './AdminPages/giftSettings';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <Routes>
          {/*  */}
          <Route path='/' element={<Layout />}>
            <Route path='/details/advert/:id' element={<AdvertDetails />} />
            <Route path='/details/company/:id' element={<CompanyDetails />} />
          </Route>

          <Route path='/' element={<Layout />}>
            <Route path='adverts' element={<Advert />} />
            <Route path='companies' element={<Companies />} />
          </Route>

          <Route path='/' element={<IsLogginLayout />}>
            <Route index element={<Home />} />
            <Route path='about' element={<About />} />
            <Route path='myProfile' element={<Profile />} />

            <Route path='details/company/:id' element={<CompanyDetails />} />
          </Route>

          <Route path='/advert' element={<IsLogginLayout />}>
            {/* Advert Pages */}
            <Route path='delete/:id' element={<DeleteAdvert />} />
            <Route path='edit/:id' element={<UpdateAdvert />} />
            <Route path='add' element={<AddAdvert />} />
          </Route>

          <Route path='/company' element={<IsLogginLayout />}>
            {/* Company Pages */}
            <Route path='add' element={<AddCompany />} />

            <Route path='delete/:id' element={<DeleteCompany />} />
            <Route path='edit/:id' element={<UpdateCompany />} />
          </Route>

          <Route path='/ads' element={<IsLogginLayout />}>
            <Route index element={<AdsPage />} />
          </Route>

          <Route path='/balance' element={<IsLogginLayout />}>
            <Route index element={<BalanceOrder />}></Route>
          </Route>

          <Route path='/chats' element={<IsLogginLayout />}>
            <Route index element={<Chats />}></Route>
            <Route path=':id' element={<Chat />}></Route>
          </Route>

          {/* Admin Panel */}
          <Route path='/admin' element={<AdminLayout />}>
            <Route index element={<AdminProfile />} />
            <Route path='company-settings' element={<CompanySettings />} />
            <Route path='company-ads' element={<CompanySettings />} />
            <Route path='users' element={<UserSettings />} />
            <Route path='gift-settings' element={<GiftSettings />} />
            <Route path='advert-settings' element={<AdvertSettings />} />
            <Route path='advert-ads' element={<CompanySettings />} />
            <Route path='site-settings' element={<SiteSettings />} />
          </Route>

          {/* Auth */}
          <Route path='/auth' element={<Auth />}>
            <Route index element={<><Navigate to={"/auth/login"} /></>} />
            <Route path='login' element={<Login/>} />
            <Route path='register' element={<Register/>} />
          </Route>
          <Route path='/auth/forgot-password' element={<ForgotPassword />} />
          <Route path='/auth/forgot-password-accept/:code' element={<ForgotPasswordAccept />} />

          {/* privacy */}
          <Route path='/privacy' element={<PrivaciyPolicy />} />

          {/* NotFound */}
          <Route path='*' element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
