import * as types from '../actionTypes/filterTypes';
import * as initialState from '../initialStates';

export default (state = initialState.chatsState, { type, payload }) => {
  switch (type) {
    case types.ADD_ADVERT_FILTER:
        return {...state,advertFilter:payload};

    case types.ADD_COMPANY_FILTER:
      return {...state,companyFilter:payload};

    case types.RESET_ADVERT_FILTER:
        return {...state,advertFilter:{}};

    case types.RESET_COMPANY_FILTER:
      return {...state,companyFilter:{}};

    default:
      return state;
  }
};
