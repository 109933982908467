import { useDispatch, useSelector } from 'react-redux';
import styles from './ForgotPassword.module.scss';
import { useState } from 'react';
import { ForgotPasswordSendEmail } from '../../../../redux/actions/ForgotPasswordAction';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const error = useSelector((x) => x.errorReducer).forgotPassword;
  const isSuccess = useSelector((x) => x.forgotPasswordReducer)?.sendEmail;
  const actions = {
    ForgotPasswordSendEmail: bindActionCreators(ForgotPasswordSendEmail, dispatch),
  };
  const [email, setEmail] = useState('');
  const submitHandle = (e) => {
    e.preventDefault();
    if (email) {
      actions.ForgotPasswordSendEmail(email);
    }
  };
  return (
    <div className={styles['forgot-password']}>
      <h1 className={styles['forgot-password__title']}>Parola Sıfırlama</h1>
      <p className={styles['forgot-password__error']}>{error}</p>
      <h6 className={styles['forgot-password__isSuccess']}>
        {isSuccess && 'E-Posta Adresinize Sıfırlama Bağlantısı Gönderildi'}
      </h6>

      <form onSubmit={submitHandle} className={styles['forgot-password__form']}>
        <div className={styles['forgot-password__form__group']}>
          <label className={styles['forgot-password__form__group__label']}>E-Posta</label>
          <input
            onChange={({ target: { value } }) => setEmail(value)}
            value={email}
            className={styles['forgot-password__form__group__input']}
            placeholder='Hesabınıza bağlı olan E-posta adresi'
          />
        </div>

        <div className={styles['forgot-password__form__group']}>
          <input
            className={styles['forgot-password__form__group__button']}
            type='submit'
            value='Sıfırlama Bağlantısı Gönder'
          />
        </div>
        <Link to={'/auth/login'} className={styles['forgot-password__form__goBack']}>
          Giriş Sayfasına Dön
        </Link>
      </form>
    </div>
  );
};

export default ForgotPassword;
