import { myAPIUrl } from '../redux/myAxios';
import * as signalR from '@microsoft/signalr';

import React, { useEffect, useRef } from 'react';
import { getUserInfo } from '../redux/actions/userActions';
import { getAllMyChats, messageAdd } from '../redux/actions/chatActions';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, redirect, useNavigate } from 'react-router-dom';

const UseSignalr = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.userReducer);
  const chats = useSelector((state) => state.chatReducer);
  const connectionRef = useRef(null);  
  const actions = {
    messageAdd: bindActionCreators(messageAdd, dispatch),
    getAllMyChats: bindActionCreators(getAllMyChats, dispatch),
  };

  useEffect(() => {
    if (!connectionRef.current?.state && user.id) {
      connectionRef.current = new signalR.HubConnectionBuilder()
        .withUrl(myAPIUrl + 'chatHub')
        .withAutomaticReconnect([
          1000, 3000, 3000, 3000, 3000, 3000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
          5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
          5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000, 5000,
        ])
        .build();

      connectionRef.current
        .start()
        .then((data) => {
          console.log('connected socket');
        })
        .catch((ex) => {
          // console.log(ex.message);
        });
    }

    if (chats != null) {
      const chatCreateHandler = (chatObj) => {
        if (chatObj.users.filter((x) => x.id == user.id).length > 0) {
          actions.getAllMyChats(user.id);
          const currentHost = window.location.host;
          const currentProtocol = window.location.protocol;

          window.location.replace(`${currentProtocol}//${currentHost}/chats/${chatObj.id}`);
        }
      };

      const readAllMessagesHandler = (userId) => {
        if (userId == user.id) {
          actions.getAllMyChats(user.id);
        }
      };

      const sendMessageHandler = (messageObj) => {
        actions.messageAdd(messageObj, user.id, chats);
      };

      const cancelMethod = () => {
        if (connectionRef.current) {
          connectionRef.current.off('sendMessage', sendMessageHandler);
          connectionRef.current.off('readAllMessages', readAllMessagesHandler);
          connectionRef.current.off('chatCreate', chatCreateHandler);
        }
      };

      if (connectionRef.current) {
        connectionRef.current.on('sendMessage', sendMessageHandler);

        connectionRef.current.on('readAllMessages', readAllMessagesHandler);

        connectionRef.current.on('chatCreate', chatCreateHandler);

        connectionRef.current.onclose(() => {
          cancelMethod();
        });
      }

      if (connectionRef.current.state == 'Disconnected') {
        cancelMethod();
      }
      return () => {
        cancelMethod();
      };
    }
  }, [user.id, connectionRef.current, chats]);

  useEffect(() => {
    if (user.id) actions.getAllMyChats(user.id);
  }, [user.id]);
  return <></>;
};

export default UseSignalr;
