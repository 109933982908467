import { Link } from 'react-router-dom';
import styles from './Notifications.module.scss';
import images from '../../images';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getAllNotifications,
  markAsRead,
  markAsReadAll,
  markAsReadAllAndDelete,
  markAsReadAndDelete,
} from '../../redux/actions/notificationActions';
import { getUserInfo } from '../../redux/actions/userActions';

const Notifications = () => {
  const [isShow, setIsShow] = useState(false);

  const user = useSelector((state) => state.userReducer);
  const notifications = useSelector((state) => state.notificationReducer);

  const dispatch = useDispatch();
  const actions = {
    getAllNotification: bindActionCreators(getAllNotifications, dispatch),
    markAsRead: bindActionCreators(markAsRead, dispatch),
    markAsReadAll: bindActionCreators(markAsReadAll, dispatch),
    markAsReadAllAndDelete: bindActionCreators(markAsReadAllAndDelete, dispatch),
    markAsReadAndDelete: bindActionCreators(markAsReadAndDelete, dispatch),
    getUserInfo: bindActionCreators(getUserInfo, dispatch),
  };

  useEffect(() => {
    actions.getUserInfo();
  }, []);

  useEffect(() => {
    if (user) {
      actions.getAllNotification(user.id);
    }
  }, [user.id]);

  return (
    <div className={styles['box']}>
      <button onClick={() => setIsShow(!isShow)} className={styles['box__icon-btn']}>
        <img src={images.notification} className={styles['box__icon-btn__image']} />
        <span className={styles['box__icon-btn__notification-count']}>
          {notifications.filter((x) => !x.isRead).length}
        </span>
      </button>
      {isShow && (
        <>
          <div onMouseDown={() => setIsShow(false)} className={styles['box__closer']}></div>
          <div className={styles['box__notifications']}>
            <header className={styles['box__notifications__header']}>
              <button
                onClick={() => actions.markAsReadAllAndDelete(user.id)}
                className={styles['box__notifications__header__button']}>
                Tüm Bildirimleri Sil
              </button>
              <button
                onClick={() => actions.markAsReadAll(user.id)}
                className={styles['box__notifications__header__button']}>
                Hepsini Okudum
              </button>
            </header>
            <ul className={styles['box__notifications__list']}>
              {notifications?.map((not, i) => (
                <li
                  key={i}
                  data-isRead={not.isRead}
                  data-status={not.status}
                  className={styles['box__notifications__list__item']}>
                  <Link
                    onClick={() => {
                      !not.isRead && actions.markAsRead(not.id, user.id);
                      not.url && setIsShow(false);
                    }}
                    to={not.url || '#'}
                    className={styles['box__notifications__list__item__detail-btn']}>
                    <h6 className={styles['box__notifications__list__item__title']}>{not.title}</h6>
                    <p className={styles['box__notifications__list__item__description']}>
                      {not.description}
                    </p>
                  </Link>
                </li>
              ))}
              {!notifications.length && <div>Hiç bildirim yok !</div>}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

export default Notifications;
