import React from 'react';

import styles from './AdminProfile.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { siteSettingsGetAll } from '../../../redux/actions/siteSettingsAction';
import { analyticsGetAll } from '../../../redux/actions/analyticsActions';

const AdminProfile = () => {
  const user = useSelector((state) => state.userReducer);
  const siteSettings = useSelector((state) => state.siteSettingsReducer);
  const analyticsReducer = useSelector((state) => state.analyticsReducer);
  const dispatch = useDispatch();
  const actions = {
    getSiteSettings: bindActionCreators(siteSettingsGetAll, dispatch),
    analyticsGetAll:bindActionCreators(analyticsGetAll,dispatch)
  };

  useEffect(() => {
    actions.getSiteSettings();
    actions.analyticsGetAll();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {'Admin Panel - ' +
            (siteSettings.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      <h1 className={styles.title}>Merhaba {user.username} Hoş Geldin.</h1>
      <div className={styles["analytics"]}>
          <div className={styles["analytics__header"]}>
            <h3>Web Site Analizleri</h3>
          </div>
          <div className={styles["analytics__analytic"]}>
            <h5 className={styles["analytics__analytic__title"]}>Bugün Kayıt Olan Kullanıcı Sayısı</h5>
            <p className={styles["analytics__analytic__data"]}>{analyticsReducer.todayRegisterUsers}</p>
          </div>
          <div className={styles["analytics__analytic"]}>
            <h5 className={styles["analytics__analytic__title"]}>Bu Hafta Kayıt Olan Kullanıcı Sayısı</h5>
            <p className={styles["analytics__analytic__data"]}>{analyticsReducer.weekRegisterUsers}</p>
          </div>
          <div className={styles["analytics__analytic"]}>
            <h5 className={styles["analytics__analytic__title"]}>Bu Ay Kayıt Olan Kullanıcı Sayısı</h5>
            <p className={styles["analytics__analytic__data"]}>{analyticsReducer.monthRegisterUsers}</p>
          </div>
          <div className={styles["analytics__analytic"]}>
            <h5 className={styles["analytics__analytic__title"]}>Toplam Kullanıcı Sayısı</h5>
            <p className={styles["analytics__analytic__data"]}>{analyticsReducer.totalRegisterUsers}</p>
          </div>

          <div className={styles["analytics__analytic"]}>
            <h5 className={styles["analytics__analytic__title"]}>Bugün Paylaşılan İlan Saysısı</h5>
            <p className={styles["analytics__analytic__data"]}>{analyticsReducer.todayAdverts}</p>
          </div>
          <div className={styles["analytics__analytic"]}>
            <h5 className={styles["analytics__analytic__title"]}>Bu Hafta Paylaşılan İlan Saysısı</h5>
            <p className={styles["analytics__analytic__data"]}>{analyticsReducer.weekAdverts}</p>
          </div>
          <div className={styles["analytics__analytic"]}>
            <h5 className={styles["analytics__analytic__title"]}>Bu Ay Paylaşılan İlan Saysısı</h5>
            <p className={styles["analytics__analytic__data"]}>{analyticsReducer.monthAdverts}</p>
          </div>
          <div className={styles["analytics__analytic"]}>
            <h5 className={styles["analytics__analytic__title"]}>Toplam İlan Sayısı</h5>
            <p className={styles["analytics__analytic__data"]}>{analyticsReducer.totalAdverts}</p>
          </div>

          <div className={styles["analytics__analytic"]}>
            <h5 className={styles["analytics__analytic__title"]}>Bugün Eklenen Firma Saysısı</h5>
            <p className={styles["analytics__analytic__data"]}>{analyticsReducer.todayCompanies}</p>
          </div>
          <div className={styles["analytics__analytic"]}>
            <h5 className={styles["analytics__analytic__title"]}>Bu Hafta Eklenen Firma Saysısı</h5>
            <p className={styles["analytics__analytic__data"]}>{analyticsReducer.weekCompanies}</p>
          </div>
          <div className={styles["analytics__analytic"]}>
            <h5 className={styles["analytics__analytic__title"]}>Bu Ay Eklenen Firma Saysısı</h5>
            <p className={styles["analytics__analytic__data"]}>{analyticsReducer.monthCompanies}</p>
          </div>
          <div className={styles["analytics__analytic"]}>
            <h5 className={styles["analytics__analytic__title"]}>Toplam Firma Sayısı</h5>
            <p className={styles["analytics__analytic__data"]}>{analyticsReducer.totalCompanies}</p>
          </div>
          
          <div className={styles["analytics__analytic"]}>
            <h5 className={styles["analytics__analytic__title"]}>Aktif Firma Reklamı Sayısı</h5>
            <p className={styles["analytics__analytic__data"]}>{analyticsReducer.totalActiveCompanyAds}</p>
          </div>
          <div className={styles["analytics__analytic"]}>
            <h5 className={styles["analytics__analytic__title"]}>Tüm Kullanıcıların Toplam Bakiyesi</h5>
            <p className={styles["analytics__analytic__data"]}>{analyticsReducer.totalUserBalance}</p>
          </div>
      </div>

   
          
    </div>
  );
};

export default AdminProfile;
