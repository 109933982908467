import React, { useEffect } from 'react';

import styles from './Home.module.scss';

import AdvertList from '../../components/advert-list/';
import CompanyList from '../../components/company-list/';
import { getUserInfo } from '../../redux/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Helmet } from 'react-helmet';
import { siteSettingsGetAll } from '../../redux/actions/siteSettingsAction';

import headerBg from '../../images/header-bg.jpg';
import { Link } from 'react-router-dom';
import Header from '../../components/header';
import images from '../../images';
import SponsorsSlider from '../../components/sponsors-slider/SponsorsSlider';
import OurContact from '../../components/our-contact';

const Home = () => {
  const siteSettings = useSelector((state) => state.siteSettingsReducer);
  const dispatch = useDispatch();
  const actions = {
    getSiteSettings: bindActionCreators(siteSettingsGetAll, dispatch),
  };

  useEffect(() => {
    actions.getSiteSettings();
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {'Anasayfa - ' +
            (siteSettings.title || "Nakliyecim.net | Türkiye'nin En Kolay Nakliye Platformu")}
        </title>
        <meta
          name='description'
          content={
            siteSettings.description ||
            "Nakliyecim.net ile Türkiye'nin her yerine kolayca nakliye hizmeti alın. Binlerce nakliye firması ve ilanı arasından seçim yapın, en uygun fiyatlı nakliye çözümünü bulun."
          }
        />
        <meta
          name='keywords'
          content={
            siteSettings.keywords ||
            'nakliye, nakliye ilanları, nakliye firmaları, nakliyecim.net, eşya taşıma, yük taşıma, parsiyel taşıma, uygun fiyatlı nakliye, Türkiye'
          }
        />
        {/* Open Graph Elements */}
        <meta property='og:locale' content='tr_TR' />
        <meta property='og:type' content='article' />
        <meta
          property='og:title'
          content='Nakliyecim.net ile artık nakliyecileri bulmak o kadar da zor değil. '
        />
        <meta
          property='og:description'
          content='Nakliye Firmaları ve nakliye ilanlarını bulabilirsiniz. Hemen Kayıt olarak sizler de aramıza katıla bilirsiniz.'
        />
        <meta property='og:url' content='https://nakliyecim.net/about' />
        <meta property='og:image' content={siteSettings.logoPath} />
      </Helmet>
      <div className={styles['home']}>
          <Header/>
          <img className='section-bracket' src={images.sectionBracket} />
          <div className='container'>
            <section className={styles['home__services']}>
              <div className={styles['home__services__content']}>
                  <h3 className={styles['home__services__content__sup-title']}>Hizmetlerimiz</h3>
                  <h2 className={styles['home__services__content__title']}>Sİzİn İçİn neler sunuyoruz?</h2>
                  <img className={styles['home__services__content__image']} src={images.sectionTransport} />
              </div>
              <div className={styles['home__services__cards']}>
                <div className={styles['home__services__cards__card']}>
                  <img src={images.megaphoneIcon} alt='megafon ikonu - nakliyecim.net' className={styles['home__services__cards__card__image']}/>
                  <div className={styles['home__services__cards__card__content']}>
                    <h6 className={styles['home__services__cards__card__content__title']}>İlanlar</h6>
                    <p className={styles['home__services__cards__card__content__description']}>
                      Nakliyecim.net platformu, taşıma ihtiyaçlarınızı kolayca paylaşabileceğiniz ve nakliye firmalarıyla hızlı bir şekilde iletişim kurabileceğiniz bir alan sunmaktadır. İlanlarınızı oluşturun ve en uygun nakliye çözümleri için teklif alın.
                    </p>
                  </div>
                </div>

                <div className={styles['home__services__cards__card']}>
                  <img src={images.companiesIcon} alt='megafon ikonu - nakliyecim.net' className={styles['home__services__cards__card__image']}/>
                  <div className={styles['home__services__cards__card__content']}>
                    <h6 className={styles['home__services__cards__card__content__title']}>Firmalar</h6>
                    <p className={styles['home__services__cards__card__content__description']}>
                      Nakliyecim.net, binlerce nakliye firmasını bir araya getirerek size en uygun firmayı bulmanızı sağlar. Firmalarımız, geniş deneyimleri ve kaliteli hizmetleri ile taşımacılık ihtiyaçlarınızı en iyi şekilde karşılar.
                    </p>
                  </div>
                </div>

                <div className={styles['home__services__cards__card']}>
                  <img src={images.diamondIcon} alt='megafon ikonu - nakliyecim.net' className={styles['home__services__cards__card__image']}/>
                  <div className={styles['home__services__cards__card__content']}>
                    <h6 className={styles['home__services__cards__card__content__title']}>Reklam Hizmeti</h6>
                    <p className={styles['home__services__cards__card__content__description']}>
                      Nakliyecim.net üzerinden firmanızın görünürlüğünü artırın. Reklam alanlarımız sayesinde firmanızın potansiyel müşterilere ulaşmasını sağlayabilir, sektördeki rekabet gücünüzü artırabilirsiniz.
                    </p>
                  </div>
                </div>

                <div className={styles['home__services__cards__card']}>
                  <img src={images.icon_7_24} alt='megafon ikonu - nakliyecim.net' className={styles['home__services__cards__card__image']}/>
                  <div className={styles['home__services__cards__card__content']}>
                    <h6 className={styles['home__services__cards__card__content__title']}>7/24 Destek</h6>
                    <p className={styles['home__services__cards__card__content__description']}>
                    Her zaman yanınızdayız! Nakliyecim.net, 7/24 müşteri desteği sunarak, taşıma işlemlerinizde yaşadığınız her türlü sorunu hızlıca çözüme kavuşturur. Bizimle her zaman iletişimde kalabilirsiniz.
                    </p>
                  </div>
                </div>
              </div>
            </section>            
          </div>
          <section className={styles['home__sponsors']}>
            <div className='container'>
              <div className={styles['home__sponsors__comments-box']}>
                  <div className={styles['home__sponsors__comments-box__content']}>
                    <h6>MÜŞTERİLERİMİZ VE ORTAKLARIMIZ</h6>
                    <h5>HİZMETLERİMİZE GÜVENİYOR</h5>
                  </div>
                  <div className={styles['home__sponsors__comments-box__comments']}>
                    <div className={styles['home__sponsors__comments-box__comments__comment']}>
                      <img src={images.commentIcon}  className={styles['home__sponsors__comments-box__comments__comment__icon']} />
                      <div className={styles['home__sponsors__comments-box__comments__comment__content']}>
                        <p className={styles['home__sponsors__comments-box__comments__comment__content__description']}>
                          Kullanıcıların tamamı ilan paylaşa bilecek. Bu ilanların içerikleri firmalar için hazırlanmalıdır örneğin taşınması gereken bir yükünüz var bunun için firmalar arasında uygun bir firma bulamadınız bunun için ilan paylaşarak firmaların sizi görmesini sağlaya bilirsiniz.
                        </p>
                        <div className={styles['home__sponsors__comments-box__comments__comment__content__author']}>
                          <img src={images.signature} className={styles['home__sponsors__comments-box__comments__comment__content__author__signature']} />
                          <p>.......</p>
                          <p>NAKLİYECİM.NET</p>
                        </div>
                      </div>
                    </div>
                    <div className={styles['home__sponsors__comments-box__comments__comment']}>
                      <img src={images.commentIcon}  className={styles['home__sponsors__comments-box__comments__comment__icon']} />
                      <div className={styles['home__sponsors__comments-box__comments__comment__content']}>
                        <p className={styles['home__sponsors__comments-box__comments__comment__content__description']}>
                          Kullanıcıların tamamı ilan paylaşa bilecek. Bu ilanların içerikleri firmalar için hazırlanmalıdır örneğin taşınması gereken bir yükünüz var bunun için firmalar arasında uygun bir firma bulamadınız bunun için ilan paylaşarak firmaların sizi görmesini sağlaya bilirsiniz.
                        </p>
                        <div className={styles['home__sponsors__comments-box__comments__comment__content__author']}>
                          <img src={images.signature} className={styles['home__sponsors__comments-box__comments__comment__content__author__signature']} />
                          <p>.......</p>
                          <p>NAKLİYECİM.NET</p>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <SponsorsSlider/>
            </div>
          </section>

          <section className={styles["home__advantages"]}>
              <div id={styles["container"]} className='container'>
                <div>
                  <h3 className={styles["home__advantages__title"]}>Neden Nakliyecim.net’i kullanmalısınız?</h3>
                  <h3 className={styles["home__advantages__sub-title"]}>GÜÇLÜ YÖNLERİMİZ VE AVANTAJLARIMIZ</h3>
                </div>
                <div className={styles["home__advantages__cards"]}>
                <div className={styles["home__advantages__cards__card"]}>
                    <img src={images.testPost} alt='paylaşım resmi'  className={styles["home__advantages__cards__card__image"]} />
                    <div className={styles["home__advantages__cards__card__content"]}>
                        <h6  className={styles["home__advantages__cards__card__content__title"]} >Kolay İletişim ve Hızlı Erişim</h6>
                        <p  className={styles["home__advantages__cards__card__content__description"]}>
                        Nakliyecim.net, nakliyat ilanları ve firmaları arasında hızlı ve kolay bir iletişim sağlar. Hem web sitesi hem de mobil uygulama üzerinden erişilebilen platformumuz, kullanıcıların ihtiyaç duyduğu taşıma hizmetlerine kolayca ulaşmasını mümkün kılar. Firmalar, yük ilanlarını doğrudan görünür kılarak hızlı bir şekilde teklif verebilir ve müşteriyle iletişime geçebilir. Böylece nakliyat süreci hem kullanıcılar hem de firmalar için hızlı ve verimli olur.
                        </p>
                    </div>
                  </div>
                  <div className={styles["home__advantages__cards__card"]}>
                    <img src={images.testPost} alt='paylaşım resmi'  className={styles["home__advantages__cards__card__image"]} />
                    <div className={styles["home__advantages__cards__card__content"]}>
                        <h6  className={styles["home__advantages__cards__card__content__title"]} >İlanlarınızı Kolayca Yayınlayın</h6>
                        <p  className={styles["home__advantages__cards__card__content__description"]}>
                        Kendi nakliyat ilanınızı oluşturmak ve paylaşmak sadece birkaç adım alır. Yük ilanları, kullanıcıların taşımacılık ihtiyaçlarını en uygun fiyatla karşılaması için çok önemli bir araçtır. Nakliyecim.net sayesinde, yük ilanınızı hızlıca oluşturabilir, isteyen firmalarla kolayca iletişim kurabilirsiniz. Böylece taşımacılık süreci daha şeffaf ve kontrol edilebilir olur.
                        </p>
                    </div>
                  </div>
                  <div className={styles["home__advantages__cards__card"]}>
                    <img src={images.testPost} alt='paylaşım resmi'  className={styles["home__advantages__cards__card__image"]} />
                    <div className={styles["home__advantages__cards__card__content"]}>
                        <h6  className={styles["home__advantages__cards__card__content__title"]} >Reklamla Firmanızı Öne Çıkarın</h6>
                        <p  className={styles["home__advantages__cards__card__content__description"]}>
                        Firmalar, Nakliyecim.net platformunda reklam vererek kendilerini öne çıkarabilir. Özel reklam alanları, firmaların potansiyel müşterilere ulaşmasını sağlar ve onların daha fazla görünür olmasına yardımcı olur. Nakliyat sektöründe rekabeti artırarak, doğru hedef kitleye ulaşmak ve daha fazla iş almak için etkili bir yöntemdir. Reklamlarınız sayesinde, firmanız daha fazla dikkat çeker ve müşteriler tarafından tercih edilir.
                        </p>
                    </div>
                  </div>
                  <div className={styles["home__advantages__cards__card"]}>
                    <img src={images.testPost} alt='paylaşım resmi'  className={styles["home__advantages__cards__card__image"]} />
                    <div className={styles["home__advantages__cards__card__content"]}>
                        <h6  className={styles["home__advantages__cards__card__content__title"]} >Geniş Seçenek ve Güvenli Ödeme Yöntemleri</h6>
                        <p  className={styles["home__advantages__cards__card__content__description"]}>
                        Nakliyecim.net, geniş bir nakliyat firması yelpazesi sunar, bu da kullanıcıların ihtiyacına en uygun çözümü bulmalarını sağlar. Tüm ilanlar güvenli ödeme yöntemleriyle desteklenir, böylece kullanıcılar ve firmalar arasındaki ödeme süreçleri güvenli bir şekilde tamamlanır. İster büyük bir taşıma işi, ister küçük bir nakliyat ihtiyacı olsun, her türlü nakliyat hizmeti için uygun seçeneklere kolayca ulaşabilirsiniz.
                        </p>
                    </div>
                  </div>
                </div>
                <img className='section-bracket' src={images.sectionBracket} />
              </div>
          </section>

          <section className={styles["home__mobile-app"]}>
            <div id={styles["container"]} className='container'>
            <div className={styles["home__mobile-app__content"]}>
              <h6 className={styles["home__mobile-app__content__title"]}>MOBİL UYGULAMAMIZI ŞİMDİ İNDİRİN</h6>
              <p className={styles["home__mobile-app__content__description"]}>İlanları mobil uygulama üzerinden takip edin hiçbir fırsatı kaçırmayın.</p>
              <div className={styles["home__mobile-app__content__links"]}>
                <Link target='_blank' to={"https://play.google.com/store/apps/details?id=com.nakliye"}>
                  <img src={images.googlePlay}  className={styles["home__mobile-app__content__links__image"]} />
                </Link>
                {/* <img src={images.appStore}  className={styles["home__mobile-app__content__links__image"]} /> */}
              </div>
            </div>
            </div>
          </section>

          {/* <OurContact/> */}

      
      </div>
    </div>
  );
};

export default Home;
